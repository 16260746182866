import React from 'react';
import { tss } from 'tss-react';

import {
  Sheet,
  Theme,
  Typography,
  useTheme,
  CircularProgress,
  Card,
  Chip,
} from '@mui/joy';
import LoadingContainer from 'src/components/LoadingContainer';
import {
  CallPrepEndCustomer,
  CallPrepKeyService,
} from 'src/generated/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    sheet: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      boxShadow: theme.shadow.md,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    chips: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

type Props = {
  data?: {
    name: string;
    foundingYear: number | null;
    description: string;
    locations: string[] | null;
    keyServices: CallPrepKeyService[] | null;
    endCustomers: CallPrepEndCustomer[] | null;
  } | null;
  loading: boolean;
};

export default function CallPrepTabContent({ data, loading }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  return (
    <LoadingContainer isLoading={loading} loadingText="Generating company tear sheet...">
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography level="h3" className={classes.title}>
            Meeting Prep
          </Typography>
          <Sheet className={classes.sheet}>
            {
              loading ?
                <CircularProgress /> :
                data ?
                  <>
                    <Typography level='h4'>
                      Business Overview - {data.name}
                    </Typography>
                    {
                      data.foundingYear &&
                      <Typography level='body-md'>
                        <i>Founded <b>{data.foundingYear}</b></i>
                      </Typography>
                    }
                    <Typography level='body-md'>
                      {data.description}
                    </Typography>
                    {
                      data.locations &&
                      <div className={classes.chips}>
                        {
                          data.locations.map((l) =>
                            <Chip variant='outlined' key={l}>
                              {l}
                            </Chip>)
                        }
                      </div>
                    }
                    <Typography level='body-lg'>
                      <b>Key Services</b>
                    </Typography>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: 20 }}>
                      {
                        data.keyServices?.map((ks) => (
                          <Card key={ks.id} variant='outlined'>
                            <Typography level='body-md'>
                              <b>{ks.name}</b>
                            </Typography>
                            <Typography level='body-sm'>
                              {ks.description}
                            </Typography>
                            <Typography level='body-sm'>
                              <b>Target Audience</b> - {ks.targetAudience}
                            </Typography>
                            <Typography level='body-sm'>
                              <b>Business Model</b> - {ks.businessModel}
                            </Typography>
                            <Typography level='body-sm'>
                              <b>Typical Margin</b> - {ks.typicalMargin}
                            </Typography>
                            <Typography level='body-sm'>
                              <b>Revenue</b>: {ks.revenueFormula}
                            </Typography>
                            <Typography level='body-sm'>
                              <b>Costs</b>: {ks.costFormula}
                            </Typography>
                          </Card>
                        ))
                      }
                    </div>
                    <Typography level='body-lg'>
                      <b>Typical End Customers</b>
                    </Typography>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: 20 }}>
                      {
                        data.endCustomers?.map((c) =>
                          <Card key={c.id}>
                            <Typography level='body-md'>
                              <b>{c.name}</b>
                            </Typography>
                            <Typography level='body-sm'>
                              {c.description}
                            </Typography>
                          </Card>)
                      }
                    </div>
                  </> :
                  <div>
                    Failed to load data
                  </div>
            }
          </Sheet>
        </div>
      </div>
    </LoadingContainer>
  );
}
