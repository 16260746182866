import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  Input,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuButton,
  MenuItem,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { GetListsResponse } from 'src/generated/api';
import {
  Business,
  Delete,
  Edit,
  ListAlt,
  MoreVert,
} from '@mui/icons-material';
import CreateListModal from 'src/modals/CreateListModal';
import RenameListModal from './RenameListModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(4),
    },
    contents: {
      maxWidth: 650,
      width: '100%',
    },
    field: {
      width: 300,
      marginBottom: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(3),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
    },
    createButton: {
      marginLeft: 'auto',
    },
    lists: {
      marginTop: theme.spacing(4),
      boxShadow: theme.shadow.lg,
      overflowY: 'auto',
      maxHeight: '70vh',
    },
    listItem: {
      padding: theme.spacing(3),
    },
    openCol: {
      width: 100,
    },
    openBtn: {
      marginLeft: 'auto',
    },
    icon: {
      width: 32,
      height: 32,
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    listItemRight: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emptyState: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: theme.shadow.lg,
    },
    optionsMenu: {
      padding: theme.spacing(1),
      width: 24,
      height: 24,
    },
    optionsIcon: {
      width: 18,
      height: 18,
    },
  }));

export default function Lists() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [lists, setLists] = useState<GetListsResponse['results'] | null>();
  const [renameModalData, setRenameModalData] =
    useState<{ id: string; name: string; } | null>(null);
  const api = getAPI();
  const fetch = useCallback(async () => {
    const res = await api.lists.getLists();
    setLists(res.data.results);
  }, [api]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const filteredLists = useMemo(() => lists?.filter(
    (list) =>
      list.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  ), [lists, searchTerm]);

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!lists}>
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography level="h3" className={classes.title}>
                <ListAlt className={classes.icon} />
                Your Lists
              </Typography>
              <Typography level='body-lg'>
                Create lead lists to enrich, qualify, or export search results
              </Typography>
            </div>
            <Button
              className={classes.createButton}
              size='lg'
              color='success'
              onClick={() => setShowCreateModal(true)}
            >
              Create
            </Button>
          </div>
          <div style={{
            marginTop: theme.spacing(2),
          }}>
            <Input
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {
            lists?.length === 0 ?
              <Card className={classes.emptyState} variant='plain'>
                <Typography level='h4'>
                  You have no lists
                </Typography>
                <Typography level='body-lg'>
                  Create your first list to save and organize businesses
                </Typography>
              </Card> :
              filteredLists?.length === 0 ?
                <Typography style={{ paddingTop: theme.spacing(4) }} level='h4'>
                  No lists found for search: "{searchTerm}"
                </Typography> :
                <List className={classes.lists}>
                  {
                    filteredLists?.map((l) => (
                      <ListItem key={l.id}>
                        <ListItemButton
                          className={classes.listItem}
                          onClick={() => navigate(`/lists/${l.id}`)}
                        >
                          <Typography level='h4'>
                            {l.name}
                          </Typography>
                          <Typography level='body-lg' className={classes.listItemRight}>
                            {l.size}
                            <Business />
                          </Typography>
                          <Dropdown>
                            <MenuButton
                              color='neutral'
                              variant='plain'
                              className={classes.optionsMenu}
                              slotProps={{
                                root: {
                                  onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  },
                                },
                              }}
                            >
                              <MoreVert className={classes.optionsIcon} />
                            </MenuButton>
                            <Menu placement='bottom-end'>
                              <MenuItem
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setRenameModalData({ id: l.id, name: l.name });
                                  }
                                }
                              >
                                <Edit />
                                Rename
                              </MenuItem>
                              <MenuItem
                                color='danger'
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  await api.lists.deleteList(l.id);
                                  await fetch();
                                }}
                              >
                                <Delete />
                                Delete
                              </MenuItem>
                            </Menu>
                          </Dropdown>
                        </ListItemButton>
                      </ListItem>
                    ))
                  }
                </List>
          }
        </div>
      </LoadingContainer>
      <CreateListModal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
        navigateOnCreate
      />
      {
        renameModalData &&
        <RenameListModal
          open
          id={renameModalData.id}
          currentName={renameModalData.name}
          onClose={() => {
            fetch();
            setRenameModalData(null);
          }}
        />
      }
    </div>
  );
}
