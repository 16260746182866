import { Download, OpenInNew } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  accordionSummaryClasses,
  Chip,
  Divider,
  IconButton,
  Link,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import { AddOnInfo, SponsorInfo } from 'src/generated/api';
import { tss } from 'tss-react';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: theme.spacing(2),
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(2),
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      border: `1px solid ${theme.palette.neutral[200]}`,
      borderRadius: theme.radius.lg,
      boxShadow: theme.shadow.md,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
      gap: theme.spacing(2),
    },
    addButton: {
      marginLeft: 'auto',
    },
    actionButtons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    table: {
      width: '100%',
      tableLayout: 'fixed',
    },
    tableCell: {
      padding: theme.spacing(1),
      verticalAlign: 'top',
    },
    acquirerName: {
      width: 250,
    },
    acquirerWebsite: {
      width: 250,
    },
    matchScore: {
      width: 80,
    },
    actions: {
      width: 200,
    },
    expandCol: {
      width: 'auto',
    },
    longContent: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflowY: 'auto',
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      width: '100%',
      backgroundColor: theme.palette.background.body,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerActions: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    list: {
      marginTop: theme.spacing(2),
    },
  }));

interface FinancialBuyersListProps {
  potentialBuyers?: {
    matchNotes: string;
    /** @format double */
    matchScore: number;
    addOnsInfo: AddOnInfo[] | null;
    sponsorsInfo: SponsorInfo[] | null;
    acquirerDescription: string | null;
    acquirerWebsite: string;
    acquirerName: string;
  }[] | null;
  handleDownloadCSV?: () => void;
}

export default function FinancialBuyersList({
  potentialBuyers,
  handleDownloadCSV,
}: FinancialBuyersListProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const normalizeUrl = (url: string) => (url.startsWith('http') ? url : `https://${url}`);

  return (
    <div>
      <div className={classes.header}>
        <Typography level='h3' style={{ flexGrow: 1 }}>
          {`Financial Buyers (${potentialBuyers?.length ?? 0})`}
        </Typography>
        <div className={classes.headerActions}>
          {handleDownloadCSV && <IconButton
            onClick={handleDownloadCSV}
            color="primary"
            variant="outlined"
            size="lg"
          >
            <Download />
          </IconButton>}
        </div>
      </div>
      <div className={classes.list}>
        {potentialBuyers?.map((buyer) => (
          <div key={buyer.acquirerName} className={classes.card}>
            {/* header */}
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Typography
                level='h4'
                endDecorator={
                  <Link
                    href={normalizeUrl(buyer.acquirerWebsite)}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <OpenInNew color='primary' />
                  </Link>
                }
                style={{ flexGrow: 1 }}>
                {buyer.acquirerName}
              </Typography>
              <Chip variant='solid'>{`Compatibility: ${(buyer.matchScore * 100).toFixed()}%`}</Chip>
            </div>
            {/* sponsors */}
            {buyer.sponsorsInfo?.length ?
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: theme.spacing(4),
                overflowX: 'auto',
              }}>
                {buyer.sponsorsInfo.map((sponsor) => (
                  <div key={sponsor.name}>
                    <Typography level='body-sm' noWrap>{sponsor.name}</Typography>
                    <Typography level='body-sm' noWrap>{sponsor.pocName}</Typography>
                    <Typography level='body-sm' noWrap>
                      <Link
                        href={normalizeUrl(sponsor.url)}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {sponsor.url}
                      </Link>
                    </Typography>
                  </div>
                ))}
              </div> : null}
            {/* description */}
            <Typography level='body-md'>{buyer.acquirerDescription}</Typography>
            {/* match notes */}
            <Typography
              level='body-sm'
              variant='soft'
              style={{
                margin: 0,
                width: '100%',
                padding: theme.spacing(1),
                paddingRight: theme.spacing(2.5),
                paddingLeft: theme.spacing(2.5),
                border: `1px solid ${theme.palette.neutral[200]}`,
                borderRadius: theme.radius.md,
                fontStyle: 'italic',
              }}>
              {buyer.matchNotes}
            </Typography>
            {/* add ons */}
            {buyer.addOnsInfo?.length ?
              <AccordionGroup
                variant='outlined'
                sx={{
                  width: '100%',
                  borderRadius: 'md',
                  [`& .${accordionSummaryClasses.button}`]: {
                    borderRadius: theme.radius.md,
                  },
                }}>
                <Accordion>
                  <AccordionSummary>
                    <Typography
                      level='title-sm'
                      style={{ padding: theme.spacing(1) }}>
                      {`Add Ons (${buyer.addOnsInfo.length})`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider />
                    {buyer.addOnsInfo.map((addOn) => (
                      <div key={addOn.name} style={{ padding: theme.spacing(2) }}>
                        <Typography level='title-sm'>
                          {addOn.name}
                        </Typography>
                        <Link
                          href={normalizeUrl(addOn.url)}
                          target='_blank'
                          rel='noopener noreferrer'>
                          <Typography>
                            {addOn.url}
                          </Typography>
                        </Link>
                        <Typography>
                          {addOn.description}
                        </Typography>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup> : null}
          </div>
        ))}
      </div>
    </div >
  );
}
