import React, { useState } from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      width: 300,
      padding: theme.spacing(2),
    },
  }));

type Props = {
  open: boolean;
  onClose: (domain: string) => void;
  navigateOnCreate?: boolean;
};

export default function CreateProspectModal({
  open,
  onClose: onCloseCallback,
  navigateOnCreate = false,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      domain: '',
    },
  });

  const onClose = () => {
    reset();
    onCloseCallback(getValues('domain'));
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onCreate: SubmitHandler<{
    domain: string;
  }> = async (data) => {
    const api = getAPI();
    setErrorMessage(null);
    setLoading(true);
    try {
      const res = await api.prospects.createProspect({
        domain: data.domain,
      });
      await api.prospects.generateTearSheet(res.data.id);
      await api.prospects.generateBuyerList(res.data.id);
      setLoading(false);
      onClose();
      if (navigateOnCreate) {
        navigate(`/prep/${res.data.id}`);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('Invalid domain');
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level='h4'>
          Website
        </Typography>
        <ControlledInput
          required
          label='Domain'
          name='domain'
          control={control}
          error={errors.domain}
        />
        {errorMessage && <Typography level='body-sm' color='danger'>{errorMessage}</Typography>}
        <Button loading={loading} onClick={handleSubmit(onCreate)}>
          Create
        </Button>
        {loading && <Typography level='body-sm'>Generating tear sheet...</Typography>}
      </ModalDialog>
    </Modal>
  );
}
