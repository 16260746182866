import {
  CircularProgress,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import { tss } from 'tss-react';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    loadingContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

type Props = {
  isLoading: boolean;
  loadingText?: string;
  children: JSX.Element | null;
};

export default function LoadingContainer({
  isLoading,
  children,
  loadingText,
}: Props): JSX.Element | null {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!isLoading) {
    return children;
  }
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress />
      {loadingText && <Typography style={{ marginTop: theme.spacing(2) }} level='h3'>
        {loadingText}
      </Typography>}
    </div>
  );
}
