import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { tss } from 'tss-react';
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Switch,
  Theme,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
  Modal,
  FormHelperText,
} from '@mui/joy';
import {
  Groups,
  Paid,
  TrendingUp,
  Tune,
  Map,
  Close,
  History,
  Clear,
  Block,
  PlaylistRemove,
  SavedSearch,
  Language,
} from '@mui/icons-material';
import { Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchIcon from '@mui/icons-material/Search';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { GetAutopilotsResponse, GetListsResponse, SearchResponse } from 'src/generated/api';
import { NumericFormatAdapter } from 'src/components/FormattedNumberInput';
import OptimizedInput from 'src/components/OptimizedInput';
import { logEvent } from 'src/analytics';
import { SnackbarContext } from 'src/contexts/snackbar';
import { usePrevious } from 'src/utils';
import SearchResults from './SearchResults';
import SearchHistoryModal, { SearchData, SearchHistoryEntry } from './HistoryModal';
import FindSimilarModal from './FindSimilarModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: '100%',
      gap: theme.spacing(2),
    },
    contents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contentsRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    field: {
      flex: 1,
    },
    searchResults: {
      overflowY: 'auto',
      height: '75vh',
      boxShadow: theme.shadow.lg,
      marginTop: theme.spacing(1),
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
    },
    filterOptions: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    filterButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    deleteFilterIcon: {
      width: 16,
      height: 16,
    },
    deleteFilterButton: {
      width: 20,
      height: 20,
      minWidth: 0,
      minHeight: 0,
      marginLeft: 'auto',
      marginBottom: 'auto',
    },
    filterTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    domainField: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      marginTop: theme.spacing(2),
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(1),
      marginTop: 0,
      marginBottom: 0,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    headerButtons: {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'row',
    },
    historyButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    similarButton: {
      height: 48,
    },
    footerButtons: {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'row',
      marginLeft: 'auto',
      padding: theme.spacing(1),
    },
    revenueInput: {
      maxWidth: 140,
    },
    employeeInput: {
      maxWidth: 80,
    },
    filterContents: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    filterContentsRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    geoFilterContents: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    geoFilterRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    weightOptions: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    weightOption: {
      borderRadius: 0,
      padding: theme.spacing(1),
    },
    scrapingText: {
      alignSelf: 'center',
    },
    card: {
      boxShadow: theme.shadow.lg,
      padding: theme.spacing(1),
      width: '100%',
    },
    headerCard: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    filtersSection: {
      marginTop: theme.spacing(2),
      maxWidth: 500,
    },
    cards: {
      display: 'flex',
      flexDirection: 'column',
      width: 400,
      gap: theme.spacing(2),
      padding: 0,
      boxShadow: theme.shadow.lg,
      marginTop: theme.spacing(1),
      minWidth: 400,
      height: '75vh',
    },
    cardsScrollContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      overflowY: 'auto',
      padding: theme.spacing(1),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    icon: {
      width: 32,
      height: 32,
    },
    cardTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    rankingTitle: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    criteriaRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    criteriaTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    domainModalContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      maxWidth: 400,
    },
    domainModalLoading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    domainModalLoadingSpinner: {
      marginTop: theme.spacing(2),
    },
    mustSellToList: {
      display: 'flex',
      flexDirection: 'column',
    },
    suggestions: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    suggestion: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    tuneButton: {
      width: 32,
      height: 32,
      marginLeft: 'auto',
    },
    autocompleteRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    autocomplete: {
      width: '100%',
    },
    simpleRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    selectButton: {
      padding: theme.spacing(1),
    },
    popper: {
      boxShadow: theme.shadow.lg,
      backgroundColor: theme.palette.background.popup,
      zIndex: theme.zIndex.popup,
    },
    popperSheet: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      overflowY: 'scroll',
      padding: theme.spacing(1),
    },
    nullState: {
      marginTop: theme.spacing(1),
      gap: theme.spacing(2),
      boxShadow: theme.shadow.lg,
      width: '100%',
      height: '75vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nullStateCard: {
      width: 500,
    },
    searchButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
      marginTop: 'auto',
      padding: theme.spacing(1),
    },
    softwareOnlyCheck: {
      marginTop: theme.spacing(1),
    },
    domainModal: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      maxWidth: 400,
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: theme.radius.sm,
    },
  }));

const searchHistoryKey = 'searchHis';

function DomainSearchModal({
  onClose,
  setSelectedBusinessId,
}: {
  onClose: () => void,
  setSelectedBusinessId: (id: string) => void,
}) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [domain, setDomain] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const api = getAPI();

  const handleSubmit = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await api.businesses.getBusinessByDomain({ domain });
      onClose();
      setSelectedBusinessId(res.data.id);
    } catch (err) {
      setError('This business does not exist in our database');
    } finally {
      setLoading(false);
    }
    return null;
  }, [domain, api, onClose, setSelectedBusinessId]);

  return (
    <Modal open onClose={() => onClose()}>
      <Sheet className={classes.domainModal}>
        <Typography level="h4">Find Business by Domain</Typography>
        <FormControl error={!!error}>
          <FormLabel>Business Website</FormLabel>
          <Input
            placeholder="example.com"
            size="lg"
            value={domain}
            onChange={(e) => {
              setDomain(e.target.value);
              setError(null);
            }}
            error={!!error}
          />
          {
            error && (
              <FormHelperText color="danger">
                {error}
              </FormHelperText>
            )
          }
        </FormControl>
        <div className={classes.searchButtons}>
          <Button
            variant="outlined"
            onClick={() => onClose()}
            size="lg"
            color="neutral"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size="lg"
            loading={loading}
            disabled={!domain}
          >
            Submit
          </Button>
        </div>
      </Sheet>
    </Modal>
  );
}

export default function Search() {
  const theme = useTheme();
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  // Search Query
  const [searchFormData, setSearchFormData] = useState<SearchData>({
    productsAndServices: [],
    productsAndServicesMatchAll: false,
    productServiceWeight: 3,
    customerSegments: [],
    customerSegmentsMatchAll: false,
    customerSegmentWeight: 1,
    softwareOnly: false,
    description: '',
  });
  const [currentSearchQuery, setCurrentSearchQuery] =
    useState<SearchData & { pageNum: number; } | null>(null);
  const [searchHistory, setSearchHistory] = useState<SearchHistoryEntry[] | null>(null);
  const [searchResults, setSearchResults] = useState<SearchResponse | null>(null);
  const [pageSize, setPageSize] = useState(100);
  const [lists, setLists] = useState<GetListsResponse['results'] | null>(null);
  const [autopilots, setAutopilots] = useState<GetAutopilotsResponse['autopilots'] | null>(null);
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);

  // Suggestions
  const [productServiceSuggestions, setProductServiceSuggestions] = useState<string[]>([]);
  const [customerSegmentSuggestions, setCustomerSegmentSuggestions] = useState<string[]>([]);
  const [showMoreProductServices, setShowMoreProductServices] = useState(false);
  const [showMoreCustomerSegments, setShowMoreCustomerSegments] = useState(false);
  const currentDescription = useRef(searchFormData.description);
  currentDescription.current = searchFormData.description;
  const currentProductsAndServices = useRef(searchFormData.productsAndServices);
  currentProductsAndServices.current = searchFormData.productsAndServices;
  const currentCustomerSegments = useRef(searchFormData.customerSegments);
  currentCustomerSegments.current = searchFormData.customerSegments;
  const prevProductsAndServices = usePrevious<string[]>(searchFormData.productsAndServices);
  const prevCustomerSegments = usePrevious<string[]>(searchFormData.customerSegments);
  const fetchSuggestions = useCallback(async (descriptionOverride?: string) => {
    const api = getAPI();
    const description = descriptionOverride ?? searchFormData.description;
    if (
      searchFormData.productsAndServices.length === 0 &&
      searchFormData.customerSegments.length === 0 &&
      description.length < 2
    ) {
      setProductServiceSuggestions([]);
      setCustomerSegmentSuggestions([]);
      return;
    }
    const res = await api.businesses.findRelatedKeywords({
      description,
      productServices: searchFormData.productsAndServices,
      customerSegments: searchFormData.customerSegments,
    });
    // Dont set if criteria changed
    if (
      currentProductsAndServices.current.length !== searchFormData.productsAndServices.length ||
      currentCustomerSegments.current.length !== searchFormData.customerSegments.length ||
      currentDescription.current !== description
    ) {
      return;
    }
    setProductServiceSuggestions(res.data.productsAndServices);
    setCustomerSegmentSuggestions(res.data.customerSegments);
  }, [
    searchFormData.customerSegments,
    searchFormData.description,
    searchFormData.productsAndServices,
  ]);
  useEffect(() => {
    if (
      prevProductsAndServices?.length === searchFormData.productsAndServices.length &&
      prevCustomerSegments?.length === searchFormData.customerSegments.length
    ) {
      return;
    }
    fetchSuggestions();
  }, [
    fetchSuggestions,
    prevCustomerSegments?.length,
    prevProductsAndServices?.length,
    searchFormData.productsAndServices.length,
    searchFormData.customerSegments.length,
  ]);

  // Actions
  const api = getAPI();
  const onClear = useCallback(() => {
    setSearchFormData({
      productsAndServices: [],
      productsAndServicesMatchAll: false,
      productServiceWeight: 3,
      customerSegments: [],
      customerSegmentsMatchAll: false,
      softwareOnly: false,
      customerSegmentWeight: 1,
      description: '',
    });
    setSearchResults(null);
  }, []);
  const onSearch = useCallback(async (data?: SearchData) => {
    const query = data ?? searchFormData;
    logEvent({
      name: 'search_submit',
      type: 'click',
      extra: {
        query: JSON.stringify(query),
      },
    });
    if (
      query!.productsAndServices.length === 0 &&
      query!.customerSegments.length === 0 &&
      query!.description.length === 0
    ) {
      setHasError(true);
      logEvent({
        name: 'search_validation_error',
        type: 'error',
        extra: {
          query: JSON.stringify(query),
        },
      });
      return;
    }
    // Persistance
    const searchHistoryStr = window.localStorage.getItem(searchHistoryKey);
    let newSearchHistory: SearchHistoryEntry[] = searchHistoryStr ?
      JSON.parse(searchHistoryStr) : [];
    newSearchHistory = [
      {
        searchData: query,
        time: (new Date()).toString(),
      },
      ...newSearchHistory!,
    ].slice(0, 50);
    window.localStorage.setItem(searchHistoryKey, JSON.stringify(newSearchHistory));
    setSearchHistory(newSearchHistory);
    setCurrentSearchQuery({
      description: query.description,
      productsAndServices: query.productsAndServices.map((ps) => ps.toLowerCase()),
      productServiceWeight: query.productServiceWeight,
      productsAndServicesMatchAll: query.productsAndServicesMatchAll,
      customerSegments: query.customerSegments.map((cs) => cs.toLowerCase()),
      customerSegmentsMatchAll: query.customerSegmentsMatchAll,
      customerSegmentWeight: query.customerSegmentWeight,
      geoFilter: query.geoFilter,
      employeeFilter: query.employeeFilter,
      revenueFilter: query.revenueFilter,
      fundingFilter: query.fundingFilter,
      excludeKeywords: query.excludeKeywords,
      excludeLists: query.excludeLists,
      softwareOnly: query.softwareOnly ?? false,
      pageNum: 0,
    });
  }, [searchFormData]);
  const performSearch = useCallback((ps: string[], cs: string[]) => {
    const searchQuery = {
      productsAndServices: ps,
      productsAndServicesMatchAll: false,
      customerSegments: cs,
      customerSegmentsMatchAll: false,
      productServiceWeight: 3,
      customerSegmentWeight: 1,
      softwareOnly: false,
      description: '',
    };
    setSearchFormData(searchQuery);
    onSearch(searchQuery);
  }, [onSearch]);
  const addAllToAutopilot = useCallback(async (campaignId: string, campaignName: string) => {
    const queryCopy = { ...currentSearchQuery! };
    // @ts-expect-error We dont want this in the params
    delete queryCopy.pageNum;
    showSnackbar({
      color: 'success',
      message: `Added all search results to ${campaignName}`,
    });
    setSearchResults((prev) => ({
      ...prev!,
      results: prev!.results
        .map((r) => ({
          ...r,
          autopilots: r.autopilots.includes(campaignId) ? r.autopilots :
            [...r.autopilots, campaignId],
        })),
    }));
    await api.autopilot.addAllFromSearch(campaignId, {
      searchQuery: queryCopy,
    });
  }, [api, currentSearchQuery, showSnackbar]);
  const addAllToList = useCallback(async (listId: string, listName: string) => {
    const queryCopy = { ...currentSearchQuery! };
    // @ts-expect-error We dont want this in the params
    delete queryCopy.pageNum;
    showSnackbar({
      color: 'success',
      message: `Added all search results to ${listName}`,
    });
    setSearchResults((prev) => ({
      ...prev!,
      results: prev!.results
        .map((r) => ({
          ...r,
          lists: r.lists.includes(listId) ? r.lists :
            [...r.lists, listId],
        })),
    }));
    await api.lists.addAllFromSearch(listId, {
      searchQuery: queryCopy,
    });
  }, [api, currentSearchQuery, showSnackbar]);
  useEffect(() => {
    if (hasError && (
      searchFormData.productsAndServices.length > 0 ||
      searchFormData.customerSegments.length > 0 ||
      searchFormData.description.length > 0
    )) {
      setHasError(false);
    }
  }, [searchFormData, hasError]);
  useEffect(() => {
    const search = async () => {
      if (currentSearchQuery == null) {
        return;
      }
      logEvent({
        name: 'search_request',
        type: 'click',
        extra: {
          query: JSON.stringify(currentSearchQuery),
        },
      });
      setLoadingSearchResults(true);
      const res = await api.businesses.searchBusinesses({
        ...currentSearchQuery,
        pageSize,
      });
      logEvent({
        name: 'search_success',
        type: 'response',
        extra: {
          query: JSON.stringify(currentSearchQuery),
          numResults: res.data.results.length.toString(),
        },
      });
      setSearchResults(res.data);
      setLoadingSearchResults(false);
    };
    search();
  }, [currentSearchQuery, api, pageSize]);
  const fetchLists = useCallback(async () => {
    const listsRes = await api.lists.getLists();
    setLists(listsRes.data.results);
    const autopilotsRes = await api.autopilot.getAutopilots();
    setAutopilots(autopilotsRes.data.autopilots);
  }, [api]);
  useEffect(() => {
    fetchLists();
  }, [fetchLists]);
  // Domain Modal
  const [showSimilarSearchModal, setShowSimilarSearchModal] = useState(false);
  const [productsPopperEl, setProductsPopperEl] = useState<HTMLAnchorElement | null>(null);
  const [customersPopperEl, setCustomersPopperEl] = useState<HTMLAnchorElement | null>(null);
  // Search history modal
  const [showHistory, setShowHistory] = useState(false);
  useEffect(() => {
    try {
      const searchHistoryStr = window.localStorage.getItem(searchHistoryKey);
      if (searchHistoryStr) {
        const typedSearchHistory = JSON.parse(searchHistoryStr) as SearchHistoryEntry[];
        // Dont directly pass it in in case the structure changes
        const searchData: SearchData = {
          productsAndServices: typedSearchHistory[0].searchData.productsAndServices!,
          productsAndServicesMatchAll:
            typedSearchHistory[0].searchData.productsAndServicesMatchAll!,
          productServiceWeight: typedSearchHistory[0].searchData.productServiceWeight!,
          customerSegments: typedSearchHistory[0].searchData.customerSegments!,
          customerSegmentsMatchAll: typedSearchHistory[0].searchData.customerSegmentsMatchAll!,
          customerSegmentWeight: typedSearchHistory[0].searchData.customerSegmentWeight!,
          description: typedSearchHistory[0].searchData.description ?? '',
          geoFilter: typedSearchHistory[0].searchData.geoFilter,
          employeeFilter: typedSearchHistory[0].searchData.employeeFilter,
          revenueFilter: typedSearchHistory[0].searchData.revenueFilter,
          fundingFilter: typedSearchHistory[0].searchData.fundingFilter,
          excludeKeywords: typedSearchHistory[0].searchData.excludeKeywords,
          excludeLists: typedSearchHistory[0].searchData.excludeLists,
          softwareOnly: typedSearchHistory[0].searchData.softwareOnly ?? false,
        };
        setSearchFormData(searchData);
        setSearchHistory(typedSearchHistory.map((sh) => ({
          searchData: {
            productsAndServices: sh.searchData.productsAndServices!,
            productsAndServicesMatchAll: sh.searchData.productsAndServicesMatchAll!,
            productServiceWeight: sh.searchData.productServiceWeight!,
            customerSegments: sh.searchData.customerSegments!,
            customerSegmentsMatchAll: sh.searchData.customerSegmentsMatchAll!,
            customerSegmentWeight: sh.searchData.customerSegmentWeight!,
            description: sh.searchData.description ?? '',
            softwareOnly: sh.searchData.softwareOnly ?? false,
          },
          time: sh.time,
        })));
      }
    } catch (err) {
      setSearchHistory([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [showDomainModal, setShowDomainModal] = useState(false);
  return (
    <>
      <LoadingContainer isLoading={!org || !user}>
        <div className={classes.container}>
          <div className={classes.contents}>
            <div className={classes.headerCard}>
              <div className={classes.header}>
                <Typography level='h3' className={classes.title}>
                  <SearchIcon className={classes.icon} />
                  Search Businesses
                </Typography>
              </div>
              <div className={classes.headerButtons}>
                <Button
                  variant="outlined"
                  size="lg"
                  color="primary"
                  className={classes.historyButton}
                  onClick={() => setShowDomainModal(true)}
                >
                  <Language />
                  Find by Domain
                </Button>
                <Button
                  variant='outlined'
                  size='lg'
                  color='warning'
                  className={classes.historyButton}
                  onClick={() => setShowSimilarSearchModal(true)}
                >
                  <SavedSearch />
                  Find Similar
                </Button>
                <Button
                  variant='outlined'
                  size='lg'
                  color='neutral'
                  className={classes.historyButton}
                  onClick={() => setShowHistory(true)}
                >
                  <History />
                  History
                </Button>
              </div>
            </div>
            <div className={classes.contentsRow}>
              <Card variant='plain' className={classes.cards}>
                <div className={classes.cardsScrollContainer}>
                  <div>
                    <div className={classes.cardContent}>
                      <div className={classes.criteriaRow}>
                        <div className={classes.criteriaTitle}>
                          <Typography level='h4'>
                            Criteria
                          </Typography>
                          <Typography level='body-sm'>
                            Search by description, what they sell, or to whom
                          </Typography>
                        </div>
                      </div>
                      {
                        hasError &&
                        <Card color='danger' variant='soft'>
                          <Typography level='body-sm'>
                            <b>
                              At least one of (description, products, customer) must
                              be specified!
                            </b>
                          </Typography>
                        </Card>
                      }
                      <FormControl
                        error={hasError}
                        className={classes.field}
                      >
                        <FormLabel>
                          Industry, Vertical, or Technology
                        </FormLabel>
                        <OptimizedInput
                          value={searchFormData.description}
                          onBlurWithValue={(val) => {
                            setSearchFormData((prev) => ({
                              ...prev,
                              description: val,
                            }));
                            fetchSuggestions(val);
                          }}
                          placeholder='Generative AI'
                        />
                        <Checkbox
                          label='Software Companies Only'
                          checked={searchFormData.softwareOnly}
                          className={classes.softwareOnlyCheck}
                          onChange={(e) => setSearchFormData((prev) => ({
                            ...prev,
                            softwareOnly: e.target.checked,
                          }))}
                        />
                      </FormControl>
                      <FormControl
                        error={hasError}
                        className={classes.field}
                      >
                        <FormLabel>
                          Products & Services
                        </FormLabel>
                        <div className={classes.autocompleteRow}>
                         <Autocomplete
                            freeSolo
                            className={classes.autocomplete}
                            placeholder='rigging, hvac, hydro blasting'
                            multiple
                            value={searchFormData.productsAndServices}
                            onChange={(e, val) =>
                              setSearchFormData((prev) => ({
                                ...prev!,
                                productsAndServices: val,
                              }))
                            }
                            autoSelect
                            onInputChange={(e, newVal) => {
                              const inputEl = e.target as HTMLInputElement;
                              if (newVal.endsWith(',')) {
                                inputEl.blur();
                                inputEl.focus();
                              }
                            }}
                            options={[]}
                            renderTags={(tags, getTagProps) =>
                              tags.map((item, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  color='warning'
                                  onClick={undefined}
                                  endDecorator={<ChipDelete
                                    onDelete={getTagProps({ index }).onClick}
                                  />}
                                >
                                  {item}
                                </Chip>
                              ))
                            }
                          />
                          <IconButton
                            className={classes.tuneButton}
                            variant='outlined'
                            onClick={(e) => {
                              if (productsPopperEl) {
                                setProductsPopperEl(null);
                              } else {
                                setProductsPopperEl(e.currentTarget);
                              }
                            }}
                          >
                            <Tune />
                          </IconButton>
                          <Popper
                            anchorEl={productsPopperEl}
                            open={Boolean(productsPopperEl)}
                            placement='bottom-end'
                            className={classes.popper}
                          >
                            <ClickAwayListener onClickAway={() => setProductsPopperEl(null)}>
                              <Sheet className={classes.popperSheet}>
                                <FormControl>
                                  <FormLabel>
                                    Importance
                                  </FormLabel>
                                  <ToggleButtonGroup
                                    value={searchFormData.productServiceWeight.toString()}
                                    onChange={(e, val) => setSearchFormData((prev) => ({
                                      ...prev,
                                      productServiceWeight: parseInt(val!, 10),
                                    }))}
                                  >
                                    <Button value='1' className={classes.selectButton}>
                                      Low
                                    </Button>
                                    <Button value='2' className={classes.selectButton}>
                                      Mid
                                    </Button>
                                    <Button value='3' className={classes.selectButton}>
                                      High
                                    </Button>
                                  </ToggleButtonGroup>
                                </FormControl>
                                <FormControl>
                                  <FormLabel>
                                    Results should match
                                  </FormLabel>
                                  <ToggleButtonGroup
                                    value={JSON.stringify(
                                      searchFormData.productsAndServicesMatchAll,
                                    )}
                                    onChange={(e, newVal) => {
                                      setSearchFormData((prev) => ({
                                        ...prev,
                                        productsAndServicesMatchAll: JSON.parse(newVal!),
                                      }));
                                    }}
                                  >
                                    <Button value='true' className={classes.selectButton}>
                                      ALL Terms
                                    </Button>
                                    <Button value='false' className={classes.selectButton}>
                                      ANY Terms
                                    </Button>
                                  </ToggleButtonGroup>
                                </FormControl>
                              </Sheet>
                            </ClickAwayListener>
                          </Popper>
                        </div>
                        {
                          productServiceSuggestions.length > 0 &&
                            <div className={classes.suggestions}>
                              {
                                productServiceSuggestions
                                  .slice(0, showMoreProductServices ? 10 : 5)
                                  .map(
                                    (ps) => (
                                      <Chip
                                        className={classes.suggestion}
                                        key={ps}
                                        color='warning'
                                        variant='outlined'
                                        onClick={() => {
                                          setProductServiceSuggestions(
                                            (prev) => prev.filter((x) => x !== ps),
                                          );
                                          setSearchFormData((prev) => ({
                                            ...prev,
                                            productsAndServices: [
                                              ...prev.productsAndServices,
                                              ps,
                                            ],
                                          }));
                                        }}
                                      >
                                        {ps}
                                      </Chip>
                                    ),
                                  )
                              }
                              {
                                !showMoreProductServices && productServiceSuggestions.length > 5 &&
                                  <Chip
                                    variant='outlined'
                                    className={classes.suggestion}
                                    onClick={() => setShowMoreProductServices(true)}
                                  >
                                    +{productServiceSuggestions.length - 5}
                                  </Chip>
                              }
                            </div>
                        }
                      </FormControl>
                      <FormControl className={classes.field} error={hasError}>
                        <FormLabel>
                          End Customer
                        </FormLabel>
                        <div className={classes.autocompleteRow}>
                          <Autocomplete
                            freeSolo
                            multiple
                            autoSelect
                            className={classes.autocomplete}
                            placeholder='industrial companies, construction, municipal'
                            value={searchFormData.customerSegments}
                            onChange={(e, val) =>
                              setSearchFormData((prev) => ({
                                ...prev!,
                                customerSegments: val,
                              }))
                            }
                            onInputChange={(e, newVal) => {
                              if (newVal.endsWith(',')) {
                                (e.target as HTMLElement).blur();
                                (e.target as HTMLElement).focus();
                              }
                            }}
                            renderTags={(tags, getTagProps) =>
                              tags.map((item, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  color='success'
                                  onClick={undefined}
                                  endDecorator={<ChipDelete
                                    onDelete={getTagProps({ index }).onClick}
                                  />}
                                >
                                  {item}
                                </Chip>
                              ))
                            }
                            options={[]}
                          />
                          <IconButton
                            className={classes.tuneButton}
                            variant='outlined'
                            onClick={(e) => {
                              if (customersPopperEl) {
                                setCustomersPopperEl(null);
                              } else {
                                setCustomersPopperEl(e.currentTarget);
                              }
                            }}
                          >
                            <Tune />
                          </IconButton>
                          <Popper
                            anchorEl={customersPopperEl}
                            open={Boolean(customersPopperEl)}
                            placement='bottom-end'
                            className={classes.popper}
                          >
                            <ClickAwayListener onClickAway={() => setCustomersPopperEl(null)}>
                              <Sheet className={classes.popperSheet}>
                                <FormControl>
                                  <FormLabel>
                                    Importance
                                  </FormLabel>
                                  <ToggleButtonGroup
                                    value={searchFormData.customerSegmentWeight.toString()}
                                    onChange={(e, val) => setSearchFormData((prev) => ({
                                      ...prev,
                                      customerSegmentWeight: parseInt(val!, 10),
                                    }))}
                                  >
                                    <Button value='1' className={classes.selectButton}>
                                      Low
                                    </Button>
                                    <Button value='2' className={classes.selectButton}>
                                      Mid
                                    </Button>
                                    <Button value='3' className={classes.selectButton}>
                                      High
                                    </Button>
                                  </ToggleButtonGroup>
                                </FormControl>
                                <FormControl>
                                  <FormLabel>
                                    Results should match
                                  </FormLabel>
                                  <ToggleButtonGroup
                                    value={JSON.stringify(searchFormData.customerSegmentsMatchAll)}
                                    onChange={(e, newVal) => {
                                      setSearchFormData((prev) => ({
                                        ...prev,
                                        customerSegmentsMatchAll: JSON.parse(newVal!),
                                      }));
                                    }}
                                  >
                                    <Button value='true' className={classes.selectButton}>
                                      ALL Terms
                                    </Button>
                                    <Button value='false' className={classes.selectButton}>
                                      ANY Terms
                                    </Button>
                                  </ToggleButtonGroup>
                                </FormControl>
                              </Sheet>
                            </ClickAwayListener>
                          </Popper>
                        </div>
                        {
                          customerSegmentSuggestions.length > 0 &&
                            <div className={classes.suggestions}>
                              {
                                customerSegmentSuggestions
                                  .slice(0, showMoreCustomerSegments ? 10 : 5)
                                  .map(
                                    (cs) => (
                                      <Chip
                                        className={classes.suggestion}
                                        key={cs}
                                        color='success'
                                        variant='outlined'
                                        onClick={() => {
                                          setCustomerSegmentSuggestions(
                                            (prev) => prev.filter((x) => x !== cs),
                                          );
                                          setSearchFormData((prev) => ({
                                            ...prev,
                                            customerSegments: [
                                              ...prev.customerSegments,
                                              cs,
                                            ],
                                          }));
                                        }}
                                      >
                                        {cs}
                                      </Chip>
                                    ),
                                  )
                              }
                              {
                                !showMoreCustomerSegments &&
                                customerSegmentSuggestions.length > 5 &&
                                  <Chip
                                    variant='outlined'
                                    className={classes.suggestion}
                                    onClick={() => setShowMoreCustomerSegments(true)}
                                  >
                                    +{customerSegmentSuggestions.length - 5}
                                  </Chip>
                              }
                            </div>
                        }
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.filtersSection}>
                    <div className={classes.cardContent}>
                      <Typography level='h4' className={classes.cardTitle}>
                        Filters
                      </Typography>
                      <div className={classes.filterOptions}>
                        {
                          !searchFormData.employeeFilter &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                employeeFilter: {
                                  minEmployees: 1,
                                  maxEmployees: 100,
                                },
                              }))}
                              className={classes.filterButton}
                            >
                              <Groups />
                              Employees
                            </Button>
                        }
                        {
                          !searchFormData.revenueFilter &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                revenueFilter: {
                                  minRevenue: 100000,
                                  maxRevenue: 100000000,
                                  mustHave: false,
                                },
                              }))}
                              className={classes.filterButton}
                            >
                              <Paid />
                              Revenue
                            </Button>
                        }
                        {
                          !searchFormData.fundingFilter &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                fundingFilter: {
                                  funded: {
                                    minFunding: 1000000,
                                    maxFunding: 100000000,
                                  },
                                  mustHave: false,
                                },
                              }))}
                              className={classes.filterButton}
                            >
                              <TrendingUp />
                              Funding (Software)
                            </Button>
                        }
                        {
                          !searchFormData.geoFilter &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                geoFilter: {
                                  hqStates: [],
                                  serviceAreasStates: [],
                                  mustHaveServiceAreas: false,
                                },
                              }))}
                              className={classes.filterButton}
                            >
                              <Map />
                              Geography
                            </Button>
                        }
                        {
                          !searchFormData.excludeKeywords &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                excludeKeywords: {
                                  productServices: [],
                                  customerSegments: [],
                                },
                              }))}
                              className={classes.filterButton}
                            >
                              <Block />
                              Exclude Keywords
                            </Button>
                        }
                        {
                          !searchFormData.excludeLists &&
                            <Button
                              variant='outlined'
                              color='neutral'
                              onClick={() => setSearchFormData((prev) => ({
                                ...prev,
                                excludeLists: [],
                              }))}
                              className={classes.filterButton}
                            >
                              <PlaylistRemove />
                              Exclude Lists
                            </Button>
                        }
                      </div>
                      {
                        searchFormData.employeeFilter &&
                          <Card className={classes.filterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <Groups />
                              <b>
                                Employees
                              </b>
                              <Tooltip title='Remove filter'>
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    employeeFilter: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <div className={classes.filterContentsRow}>
                              <FormControl>
                                <FormLabel>
                                  Min
                                </FormLabel>
                                <Input
                                  value={searchFormData.employeeFilter.minEmployees?.toString()}
                                  className={classes.employeeInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) => setSearchFormData((prev) => ({
                                    ...prev,
                                    employeeFilter: {
                                      ...prev.employeeFilter!,
                                      minEmployees: parseInt(e.target.value, 10),
                                    },
                                  }))}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>
                                  Max
                                </FormLabel>
                                <Input
                                    value={searchFormData.employeeFilter.maxEmployees?.toString()}
                                    className={classes.employeeInput}
                                    slotProps={{
                                      input: {
                                        inputMode: 'numeric',
                                        component: NumericFormatAdapter,
                                      },
                                    }}
                                    onChange={(e) => setSearchFormData((prev) => ({
                                      ...prev,
                                      employeeFilter: {
                                        ...prev.employeeFilter!,
                                        maxEmployees: parseInt(e.target.value, 10),
                                      },
                                    }))}
                                  />
                              </FormControl>
                            </div>
                          </Card>
                      }
                      {
                        searchFormData.revenueFilter &&
                          <Card className={classes.filterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <Paid />
                              <b>
                                Revenue
                              </b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    revenueFilter: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <div className={classes.filterContentsRow}>
                              <FormControl>
                                <FormLabel>
                                  Min
                                </FormLabel>
                                <Input
                                  value={searchFormData.revenueFilter.minRevenue?.toString()}
                                  startDecorator='$'
                                  className={classes.revenueInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) => setSearchFormData((prev) => ({
                                    ...prev,
                                    revenueFilter: {
                                      ...prev.revenueFilter!,
                                      minRevenue: parseInt(e.target.value, 10),
                                    },
                                  }))}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>
                                  Max
                                </FormLabel>
                                <Input
                                  value={searchFormData.revenueFilter.maxRevenue?.toString()}
                                  startDecorator='$'
                                  className={classes.revenueInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) => setSearchFormData((prev) => ({
                                    ...prev,
                                    revenueFilter: {
                                      ...prev.revenueFilter!,
                                      maxRevenue: parseInt(e.target.value, 10),
                                    },
                                  }))}
                                />
                              </FormControl>
                            </div>
                            <Checkbox
                              label="Exclude if revenue unknown"
                              checked={searchFormData.revenueFilter.mustHave}
                              onChange={() => setSearchFormData((prev) => ({
                                ...prev,
                                revenueFilter: {
                                  ...prev.revenueFilter!,
                                  mustHave: !prev.revenueFilter!.mustHave,
                                },
                              }))}
                            />
                          </Card>
                      }
                      {
                        searchFormData.fundingFilter &&
                          <Card className={classes.filterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <TrendingUp />
                              <b>
                                Funding (Software)
                              </b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    fundingFilter: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <Switch
                              startDecorator="Bootstrapped"
                              endDecorator="Funded"
                              checked={searchFormData.fundingFilter.funded != null}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    fundingFilter: {
                                      funded: {
                                        minFunding: 1000000,
                                        maxFunding: 1000000000,
                                      },
                                      mustHave: prev.fundingFilter?.mustHave ?? false,
                                    },
                                  }));
                                } else {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    fundingFilter: {
                                      mustHave: prev.fundingFilter?.mustHave ?? false,
                                    },
                                  }));
                                }
                              }}
                            />
                            <div className={classes.filterContentsRow}>
                              {
                                searchFormData.fundingFilter.funded &&
                                  <>
                                    <FormControl>
                                      <FormLabel>
                                        Min
                                      </FormLabel>
                                      <Input
                                        value={searchFormData.fundingFilter.funded
                                          .minFunding?.toString()}
                                        startDecorator='$'
                                        className={classes.revenueInput}
                                        slotProps={{
                                          input: {
                                            inputMode: 'numeric',
                                            component: NumericFormatAdapter,
                                          },
                                        }}
                                        onChange={(e) => setSearchFormData((prev) => ({
                                          ...prev,
                                          fundingFilter: {
                                            ...prev.fundingFilter!,
                                            funded: {
                                              ...prev.fundingFilter!.funded!,
                                              minFunding: parseInt(e.target.value, 10),
                                            },
                                          },
                                        }))}
                                      />
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel>
                                        Max
                                      </FormLabel>
                                      <Input
                                        value={searchFormData.fundingFilter.funded
                                          .maxFunding?.toString()}
                                        startDecorator='$'
                                        className={classes.revenueInput}
                                        slotProps={{
                                          input: {
                                            inputMode: 'numeric',
                                            component: NumericFormatAdapter,
                                          },
                                        }}
                                        onChange={(e) => setSearchFormData((prev) => ({
                                          ...prev,
                                          fundingFilter: {
                                            ...prev.fundingFilter!,
                                            funded: {
                                              ...prev.fundingFilter!.funded!,
                                              maxFunding: parseInt(e.target.value, 10),
                                            },
                                          },
                                        }))}
                                      />
                                    </FormControl>
                                  </>
                              }
                            </div>
                            <Checkbox
                              label="Exclude if funding unknown"
                              checked={searchFormData.fundingFilter.mustHave}
                              onChange={() => setSearchFormData((prev) => ({
                                ...prev,
                                fundingFilter: {
                                  ...prev.fundingFilter!,
                                  mustHave: !prev.fundingFilter!.mustHave,
                                },
                              }))}
                            />
                          </Card>
                      }
                      {
                        searchFormData.geoFilter &&
                          <Card className={classes.geoFilterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <Map />
                              <b>
                                Geography
                              </b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    geoFilter: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <div className={classes.simpleRow}>
                                <FormLabel>
                                  HQ (State)
                                </FormLabel>
                              </div>
                              <Autocomplete
                                freeSolo
                                multiple
                                placeholder='NY, CA, TX'
                                value={searchFormData.geoFilter.hqStates}
                                autoSelect
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev!,
                                    geoFilter: {
                                      ...prev.geoFilter!,
                                      hqStates: val,
                                    },
                                  }))
                                }
                                onInputChange={(e, newVal) => {
                                  if (newVal.endsWith(',')) {
                                    (e.target as HTMLElement).blur();
                                    (e.target as HTMLElement).focus();
                                  }
                                }}
                                renderTags={(tags, getTagProps) =>
                                  tags.map((item, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      color='primary'
                                      onClick={undefined}
                                      endDecorator={<ChipDelete
                                        onDelete={getTagProps({ index }).onClick}
                                      />}
                                    >
                                      {item}
                                    </Chip>
                                  ))
                                }
                                slotProps={{
                                  input: {
                                    maxLength: 2,
                                  },
                                }}
                                options={[]}
                              />
                            </FormControl>
                            <FormControl className={classes.field}>
                              <FormLabel>
                                Service Area (State)
                              </FormLabel>
                              <Autocomplete
                                freeSolo
                                multiple
                                placeholder='NY, CA, TX'
                                value={searchFormData.geoFilter.serviceAreasStates}
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev!,
                                    geoFilter: {
                                      ...prev.geoFilter!,
                                      serviceAreasStates: val.map((v) => v.toUpperCase()),
                                    },
                                  }))
                                }
                                autoSelect
                                onInputChange={(e, newVal) => {
                                  if (newVal.endsWith(',')) {
                                    (e.target as HTMLElement).blur();
                                    (e.target as HTMLElement).focus();
                                  }
                                }}
                                renderTags={(tags, getTagProps) =>
                                  tags.map((item, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      color='primary'
                                      onClick={undefined}
                                      endDecorator={<ChipDelete
                                        onDelete={getTagProps({ index }).onClick}
                                      />}
                                    >
                                      {item}
                                    </Chip>
                                  ))
                                }
                                options={[]}
                                slotProps={{
                                  input: {
                                    maxLength: 2,
                                  },
                                }}
                              />
                          </FormControl>
                          <Checkbox
                            label="Exclude if no service areas"
                            checked={searchFormData.geoFilter.mustHaveServiceAreas}
                            onChange={() => setSearchFormData((prev) => ({
                              ...prev,
                              geoFilter: {
                                ...prev.geoFilter!,
                                mustHaveServiceAreas: !prev.geoFilter!.mustHaveServiceAreas,
                              },
                            }))}
                          />
                        </Card>
                      }
                      {
                        searchFormData.excludeKeywords &&
                          <Card className={classes.filterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <Block />
                              <b>
                                Exclude Keywords
                              </b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    excludeKeywords: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <FormLabel>
                                Products & Services
                              </FormLabel>
                              <div className={classes.autocompleteRow}>
                                <Autocomplete
                                  freeSolo
                                  className={classes.autocomplete}
                                  placeholder='rigging, hvac, hydro blasting'
                                  multiple
                                  value={searchFormData.excludeKeywords.productServices}
                                  onChange={(e, val) =>
                                    setSearchFormData((prev) => ({
                                      ...prev!,
                                      excludeKeywords: {
                                        ...prev.excludeKeywords!,
                                        productServices: val,
                                      },
                                    }))
                                  }
                                  autoSelect
                                  onInputChange={(e, newVal) => {
                                    const inputEl = e.target as HTMLInputElement;
                                    if (newVal.endsWith(',')) {
                                      inputEl.blur();
                                      inputEl.focus();
                                    }
                                  }}
                                  options={[]}
                                  renderTags={(tags, getTagProps) =>
                                    tags.map((item, index) => (
                                      <Chip
                                        {...getTagProps({ index })}
                                        color='warning'
                                        onClick={undefined}
                                        endDecorator={<ChipDelete
                                          onDelete={getTagProps({ index }).onClick}
                                        />}
                                      >
                                        {item}
                                      </Chip>
                                    ))
                                  }
                                />
                              </div>
                            </FormControl>
                            <FormControl className={classes.field} error={hasError}>
                              <FormLabel>
                                End Customer
                              </FormLabel>
                              <div className={classes.autocompleteRow}>
                                <Autocomplete
                                  freeSolo
                                  multiple
                                  autoSelect
                                  className={classes.autocomplete}
                                  placeholder='industrial companies, construction, municipal'
                                  value={searchFormData.excludeKeywords.customerSegments}
                                  onChange={(e, val) =>
                                    setSearchFormData((prev) => ({
                                      ...prev!,
                                      excludeKeywords: {
                                        ...prev.excludeKeywords!,
                                        customerSegments: val,
                                      },
                                    }))
                                  }
                                  onInputChange={(e, newVal) => {
                                    if (newVal.endsWith(',')) {
                                      (e.target as HTMLElement).blur();
                                      (e.target as HTMLElement).focus();
                                    }
                                  }}
                                  renderTags={(tags, getTagProps) =>
                                    tags.map((item, index) => (
                                      <Chip
                                        {...getTagProps({ index })}
                                        color='success'
                                        onClick={undefined}
                                        endDecorator={<ChipDelete
                                          onDelete={getTagProps({ index }).onClick}
                                        />}
                                      >
                                        {item}
                                      </Chip>
                                    ))
                                  }
                                  options={[]}
                                />
                              </div>
                            </FormControl>
                          </Card>
                      }
                      {
                        searchFormData.excludeLists &&
                          <Card className={classes.filterContents}>
                            <Typography level='body-md' className={classes.filterTitle}>
                              <PlaylistRemove />
                              <b>
                                Exclude Lists
                              </b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size='sm'
                                  variant='outlined'
                                  color='danger'
                                  className={classes.deleteFilterButton}
                                  onClick={() => setSearchFormData(((prev) => ({
                                    ...prev,
                                    excludeLists: undefined,
                                  })))}
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <FormLabel>
                                Lists to exclude from results
                              </FormLabel>
                              <Select
                                multiple
                                value={searchFormData.excludeLists}
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    excludeLists: val,
                                  }))
                                }
                              >
                                {
                                  lists?.map((l) => (
                                    <Option value={l.id}>
                                      { l.name }
                                    </Option>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Card>
                      }
                      </div>
                    </div>
                  </div>
                <div className={classes.searchButtons}>
                  <Button
                    variant='outlined'
                    onClick={onClear}
                    size='lg'
                    color='danger'
                  >
                    <Clear />
                    Clear
                  </Button>
                  <Button onClick={() => onSearch()} size='lg' color='success'>
                    <SearchIcon />
                    Search
                  </Button>
                </div>
              </Card>
                {
                  (searchResults || loadingSearchResults) ?
                    <div className={classes.searchResults}>
                      <SearchResults
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        loadingSearchResults={loadingSearchResults}
                        hasDescription={currentSearchQuery!.description.length > 0}
                        results={searchResults}
                        pageNum={currentSearchQuery!.pageNum}
                        numCurrentQueryProductServices={
                          currentSearchQuery!.productsAndServices.length
                        }
                        numCurrentQueryCustomerSegments={
                          currentSearchQuery!.customerSegments.length
                        }
                        numCurrentQueryServiceAreas={
                          currentSearchQuery!.geoFilter?.serviceAreasStates.length ?? 0}
                        productsAndServices={searchFormData.productsAndServices}
                        customerSegments={searchFormData.customerSegments}
                        setSearchFormData={setSearchFormData}
                        setPage={(pageNum: number) =>
                          setCurrentSearchQuery((prev) => ({ ...prev!, pageNum }))
                        }
                        setSearchResults={setSearchResults}
                        showBusinessDrawer={setSelectedBusinessId}
                        lists={lists}
                        autopilots={autopilots}
                        refetchListsAndAutopilots={fetchLists}
                        addAllToList={addAllToList}
                        addAllToAutopilot={addAllToAutopilot}
                      />
                    </div> :
                    <Card variant='plain' className={classes.nullState}>
                      <Typography level='h3'>
                        Start a Thesis-Driven Business Search
                      </Typography>
                      <Card className={classes.nullStateCard}>
                        <Typography level='body-lg'>
                          1. If you don&apos;t have a specific criteria in mind, start
                          with an industry, vertical, or technology that you&apos;re interested
                          in and click "Search".
                        </Typography>
                      </Card>
                      <Card className={classes.nullStateCard}>
                        <Typography level='body-lg'>
                          2. Chisel down your thesis by adding Products & Services or End Markets
                          served to your search criteria
                        </Typography>
                      </Card>
                      <Card className={classes.nullStateCard}>
                        <Typography level='body-lg'>
                          3. Add businesses that interest you to a list
                        </Typography>
                      </Card>
                      <Card className={classes.nullStateCard}>
                        <Typography level='body-lg'>
                          4. Add enrichments to your list to find contact info of a business owner,
                          have an AI agent research and answer questions about a business, or draft
                          a personalized email
                        </Typography>
                      </Card>
                    </Card>
                }
              </div>
            </div>
          </div>
      </LoadingContainer>
      {
        showSimilarSearchModal &&
          <FindSimilarModal
            onClose={(data) => {
              if (data) {
                const searchData = {
                  productsAndServices: data.productsAndServices,
                  productsAndServicesMatchAll: false,
                  productServiceWeight: 3,
                  customerSegments: data.customerSegments,
                  customerSegmentsMatchAll: false,
                  customerSegmentWeight: 1,
                  softwareOnly: false,
                  description: '',
                };
                setSearchFormData(searchData);
              }
              setShowSimilarSearchModal(false);
            }}
          />
      }
      {
        lists &&
          <BusinessDrawer
            initialBusinessId={selectedBusinessId}
            onClose={() => setSelectedBusinessId(null)}
            lists={lists}
            search={{
              performSearch: (ps, cs) => {
                setSelectedBusinessId(null);
                performSearch(ps, cs);
              },
            }}
          />
      }
      <SearchHistoryModal
        open={showHistory}
        searchHistory={searchHistory ?? []}
        onClose={(selectedHistory?: SearchData) => {
          if (selectedHistory) {
            setSearchFormData(selectedHistory);
            onSearch(selectedHistory);
          }
          setShowHistory(false);
        }}
      />
      {showDomainModal && (
        <DomainSearchModal
          setSelectedBusinessId={setSelectedBusinessId}
          onClose={() => setShowDomainModal(false)}
        />
      )}
    </>
  );
}
