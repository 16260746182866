import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import * as Sentry from '@sentry/react';
import { tss } from 'tss-react';

import {
  Button,
  Card,
  Chip,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { GetListResponse, GetListsResponse, GetSubscriptionResponse } from 'src/generated/api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Add,
  AutoAwesome,
  Block,
  CloudDownload,
  ContentCopy,
  Delete,
  DriveFileMove,
  Edit,
  MoreVert,
  PlayArrow,
  SavedSearch,
  UploadFile,
} from '@mui/icons-material';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
  GridSortModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { getConfig } from 'src/config';
import { logEvent } from 'src/analytics';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import { SnackbarContext } from 'src/contexts/snackbar';
import { usePrevious } from 'src/utils';
import { AuthContext } from 'src/contexts/auth';
import Footer from 'src/components/DataGridFooter';
import CreateListModal from 'src/modals/CreateListModal';
import EnrichmentModal from './EnrichmentModal';
import RenameListModal from './RenameListModal';
import SubscriptionModal from './SubscriptionModal';
import ImportModal from './ImportModal';
import ImportErrorModal from './ImportErrorModal';
import AboutCell from './Cells/AboutCell';
import FinancialCell from './Cells/FinancialCell';
import ProductServiceCell from './Cells/ProductsServicesCell';
import CustomerSegmentsCell from './Cells/CustomerSegmentsCell';
import ServiceAreasCell from './Cells/ServiceAreasCell';
import { getColumnsForEnrichment } from './Enrichments';
import EnrichmentHeader from './Enrichments/Header';
import SimpleCell from './SimpleCell';
import EnrichmentScrapeStepsModal from './Enrichments/EnrichmentStepsModal';
import SimilarBusinessesModal from './SimilarBusinessesModal';
import DuplicateListModal from './DuplicateListModal';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'block',
      width: '100%',
      padding: theme.spacing(2),
    },
    field: {
      width: 300,
      marginBottom: 12,
    },
    button: {
      margin: 12,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: theme.spacing(2),
      alignItems: 'center',
    },
    createButton: {
      marginLeft: 'auto',
    },
    footer: {
      width: '100%',
    },
    footerCell: {
      width: '100%',
    },
    footerContents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    checkboxColumn: {
      width: 64,
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    thead: {
      height: 64,
    },
    headerButtons: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
      gap: theme.spacing(1),
    },
    emptyState: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
    listingRow: {
    },
    enrichmentModal: {
      padding: theme.spacing(2),
      width: 500,
      overflow: 'scroll',
    },
    columnContents: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    enrichmentHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
    },
    enrichmentButton: {
      width: 28,
      height: 28,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
    },
    enrichActions: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    enrichmentIcon: {
      width: 20,
      height: 20,
    },
    table: {
    },
    infoColumn: {
      width: 300,
      zIndex: '16 !important',
    },
    descriptionColumn: {
      width: 600,
    },
    financialsColumn: {
      width: 100,
    },
    chipsColumn: {
      width: 600,
    },
    statesColumn: {
      width: 300,
    },
    phoneColumn: {
      width: 150,
    },
    websiteColumn: {
      width: 250,
    },
    colHeaderText: {
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    checkboxColHeaderText: {
      marginLeft: theme.spacing(1),
    },
    tableContainer: {
      width: '100%',
      height: '75vh',
    },
    tableScroller: {
      '::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: 12,
        height: 12,
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
    },
    headerButton: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
    },
    importChip: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    importChipLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
    },
    uploadButton: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    cell: {
      wordBreak: 'break-word',
    },
    creditsLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    },
    creditsLeftChip: {
      marginLeft: theme.spacing(2),
    },
    noMoreCreditsBanner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    chipCancelButton: {
      padding: 0,
      minWidth: 20,
      minHeight: 20,
      width: 20,
      height: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    chipCancelIcon: {
      width: 16,
      height: 16,
    },
    listsMenu: {
      width: 220,
      maxHeight: 400,
      overflowY: 'auto',
    },
  }));

type PersistedColumns = {
  orderedColumns: string[];
  columnWidths: Record<string, number>;
  sortModel: GridSortModel;
  visibilityModel: GridColumnVisibilityModel;
};

const pageSize = 3000;
export default function List() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes } = useStyles({ theme });
  const { id } = useParams();
  const persistedColumnsKey = `list-columns-${id}`;
  const {
    creditsLeft,
    refetchCreditsLeft,
  } = useContext(AuthContext);
  // Modals and Panels
  const [showSimilarBusinesses, setShowSimilarBusinesses] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [showEnrichmentModal, setShowEnrichmentModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showImportErrorModal, setShowImportErrorModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [infoModalData, setInfoModalData] =
    useState<{
      prompt: string;
      value: string;
      explanation: string | null;
      steps: {
        str: string;
        imgUrl: string | null;
      }[];
    } | null>(null);
  // Subscription and Billing
  const [subscription, setSubscription] = useState<GetSubscriptionResponse | null>(null);
  const api = getAPI();
  // Lists
  const [lists, setLists] = useState<GetListsResponse['results'] | null>(null);
  const fetchLists = useCallback(async () => {
    const listsRes = await api.lists.getLists();
    setLists(listsRes.data.results);
  }, [api]);
  useEffect(() => {
    fetchLists();
  }, [fetchLists]);
  // Pagination
  const [list, setList] = useState<GetListResponse | null>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize,
  });
  const [enrichmentModalVal, setEnrichmentModalVal] =
    useState<GetListResponse['enrichmentColumns'][number] | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useCallback(async () => {
    const res = await api.lists.getList(id!, {
      pageNum: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
    setList(res.data);
    refetchCreditsLeft();
  }, [api, id, paginationModel, refetchCreditsLeft]);
  const prevPaginationModel = usePrevious(paginationModel);
  useEffect(() => {
    if (prevPaginationModel?.page !== paginationModel.page) {
      fetch();
    }
  }, [fetch, prevPaginationModel?.page, paginationModel.page]);
  // Grid State
  const gridApiRef = useGridApiRef();
  const [columnOrder, setColumnOrder] = React.useState<string[]>([]);
  const [columnWidths, setColumnWidths] = React.useState<Record<string, number>>({});
  const [visibilityModel, setVisibilityModel] = React.useState<GridColumnVisibilityModel>({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const getFirstTenRowIds = useCallback(() => (
    gridApiRef.current?.getSortedRowIds ?
      gridApiRef.current.getSortedRowIds()
        .splice(0, 10) as string[] : []
  ), [gridApiRef]);
  const columns: GridColDef[] = useMemo(() => ([{
    field: 'about',
    width: columnWidths.about ?? 350,
    disableReorder: true,
    headerName: 'About',
    filterable: false,
    valueGetter: (data: GridValueGetterParams<GetListResponse['businesses'][number]>) =>
      data.row.name,
    renderCell: (data) => (
      <AboutCell
        business={data.row}
        setDrawerId={setSelectedBusinessId}
      />
    ),
    disableColumnMenu: true,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          About
        </b>
      </Typography>
    ),
  }, {
    field: 'description',
    width: columnWidths.description ?? 500,
    disableColumnMenu: true,
    headerName: 'Description',
    cellClassName: classes.cell,
    renderCell: (data) => (
      <SimpleCell
        value={data.value}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Description
        </b>
      </Typography>
    ),
  }, {
    field: 'city',
    width: columnWidths.city ?? 120,
    disableColumnMenu: true,
    headerName: 'City',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          HQ City
        </b>
      </Typography>
    ),
  }, {
    field: 'state',
    width: columnWidths.state ?? 120,
    disableColumnMenu: true,
    headerName: 'HQ State',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          HQ State
        </b>
      </Typography>
    ),
  }, {
    field: 'phone',
    width: columnWidths.phone ?? 120,
    disableColumnMenu: true,
    headerName: 'Phone',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Phone
        </b>
      </Typography>
    ),
  }, {
    field: 'numEmployees',
    width: columnWidths.numEmployees ?? 150,
    disableColumnMenu: true,
    headerName: '# Employees',
    cellClassName: classes.cell,
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          # Employees
        </b>
      </Typography>
    ),
  }, {
    field: 'revenue',
    width: columnWidths.revenue ?? 120,
    disableColumnMenu: true,
    headerName: 'Revenue',
    type: 'number',
    renderCell: (data) => (
      <FinancialCell
        num={data.row.revenue}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Revenue
        </b>
      </Typography>
    ),
  }, {
    field: 'funding',
    type: 'number',
    width: columnWidths.funding ?? 120,
    disableColumnMenu: true,
    headerName: 'Funding',
    renderCell: (data) => (
      <FinancialCell
        num={data.row.funding}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Funding
        </b>
      </Typography>
    ),
  }, {
    field: 'productServices',
    width: columnWidths.productServices ?? 500,
    disableColumnMenu: true,
    headerName: 'Products and Services',
    sortable: false,
    filterable: false,
    renderCell: (data) => (
      <ProductServiceCell
        business={data.row}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Products and Services
        </b>
      </Typography>
    ),
  }, {
    field: 'customerSegments',
    width: columnWidths.customerSegments ?? 500,
    disableColumnMenu: true,
    filterable: false,
    headerName: 'End Customers',
    sortable: false,
    renderCell: (data) => (
      <CustomerSegmentsCell
        business={data.row}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Customer Segments
        </b>
      </Typography>
    ),
  }, {
    field: 'serviceAreas',
    sortable: false,
    filterable: false,
    width: columnWidths.serviceAreas ?? 200,
    disableColumnMenu: true,
    headerName: 'Service Areas',
    renderCell: (data) => (
      <ServiceAreasCell
        business={data.row}
      />
    ),
    renderHeader: () => (
      <Typography level='body-md'>
        <b>
          Service Areas
        </b>
      </Typography>
    ),
  },
  ...(list?.enrichmentColumns.flatMap((col) =>
    getColumnsForEnrichment({
      col,
      listId: id!,
      getFirstTenRowIds,
      selectedRows: rowSelectionModel as string[],
      showEnrichmentModal: () => {
        setEnrichmentModalVal(col);
        setShowEnrichmentModal(true);
      },
      resetSelectedRows: () => setRowSelectionModel([]),
      columnWidths,
      refetch: fetch,
      onInfoClick: (data) => {
        setInfoModalData(data);
      },
    })) ?? []),
  ] as GridColDef[]).sort((a, b) => {
    let aIdx = columnOrder.findIndex((field) => field === a.field);
    let bIdx = columnOrder.findIndex((field) => field === b.field);
    if (aIdx === -1) {
      aIdx = 999;
    }
    if (bIdx === -1) {
      bIdx = 999;
    }
    return aIdx - bIdx;
  }), [
    classes.cell,
    fetch,
    getFirstTenRowIds,
    id,
    list?.enrichmentColumns,
    columnWidths,
    columnOrder,
    rowSelectionModel,
  ]);
  const saveColumnsState = useCallback(() => {
    setTimeout(() => {
      const colsState = gridApiRef.current.exportState();
      const colDims = colsState.columns!.dimensions!;
      const newColumnWidths = Object.keys(colDims).map((field) => ({
        field,
        width: colDims[field].width!,
      })).reduce((a, v) => ({ ...a, [v.field]: v.width }), {});
      setColumnWidths(newColumnWidths);
      setColumnOrder(colsState.columns!.orderedFields!);
      const columnState: PersistedColumns = {
        sortModel: colsState.sorting!.sortModel!,
        orderedColumns: colsState.columns!.orderedFields!,
        visibilityModel: colsState.columns!.columnVisibilityModel!,
        columnWidths: newColumnWidths,
      };
      window.localStorage.setItem(persistedColumnsKey, JSON.stringify(columnState));
    }, 100);
  }, [gridApiRef, persistedColumnsKey]);
  const prevList = usePrevious(list);
  useEffect(() => {
    if (prevList == null && list != null) {
      // Restore persisted state on mount
      setTimeout(() => {
        try {
          const colStateStr = window.localStorage.getItem(persistedColumnsKey);
          if (colStateStr) {
            const typedColState = JSON.parse(colStateStr) as PersistedColumns;
            setSortModel(typedColState.sortModel);
            setVisibilityModel(typedColState.visibilityModel);
            setColumnOrder(typedColState.orderedColumns);
            // Fixes an old bug that is now persisted
            if (typeof typedColState.columnWidths.state === 'number') {
              setColumnWidths(typedColState.columnWidths);
            }
          }
        } catch (err) {
          // Do nothing
          Sentry.captureException(err);
        }
      }, 0);
    }
  }, [persistedColumnsKey, gridApiRef, prevList, list]);

  useEffect(() => {
    api.billing.getSubscription()
      .then((res) => {
        setSubscription(res.data);
      });
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      if (
        list && (
          list.businesses.some(
            (b) => b.enrichmentValues.some(
              (e) => e.status === 'QUEUED' || e.status === 'RUNNING',
            ),
          ) || list.numBusinessesImporting > 0
        )
      ) {
        fetch();
      }
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetch, api, list]);

  const removeFromList = useCallback(async () => {
    logEvent({
      name: 'lists_remove',
      type: 'click',
      extra: {
        numBusinesses: rowSelectionModel.length.toString(),
      },
    });
    await api.lists.removeFromList(id!, {
      businessIds: rowSelectionModel as string[],
    });
    logEvent({
      name: 'lists_remove_success',
      type: 'response',
      extra: {
        numBusinesses: rowSelectionModel.length.toString(),
      },
    });
    setRowSelectionModel([]);
    fetch();
  }, [api, id, rowSelectionModel, fetch]);

  const deleteList = useCallback(async () => {
    logEvent({
      name: 'lists_delete',
      type: 'click',
      extra: {
        id: id!,
      },
    });
    await api.lists.deleteList(id!);
    logEvent({
      name: 'lists_delete_success',
      type: 'response',
      extra: {
        id: id!,
      },
    });
    navigate('/lists');
  }, [api, id, navigate]);

  const moveToList = useCallback(async (newListId: string) => {
    await api.lists.moveBusinesses(id!, {
      newListId,
      businessIds: rowSelectionModel as string[],
    });
    setRowSelectionModel([]);
    fetch();
  }, [api, id, rowSelectionModel, fetch]);

  const runEnrichmentsOnSelected = useCallback(async () => {
    const colIds = list?.enrichmentColumns.map((x) => x.id) ?? [];
    showSnackbar({
      color: 'success',
      message: `Started enrichment for ${rowSelectionModel.length} rows`,
    });
    setRowSelectionModel([]);
    await api.lists.runSpecificEnrichments(
      id!,
      {
        colIds,
        businessIds: rowSelectionModel as string[],
      },
    );
    setTimeout(() => {
      fetch();
    }, 1000);
    logEvent({
      name: 'run_select_enrichment_click',
      type: 'click',
      extra: {
        numCells: (colIds.length * rowSelectionModel.length).toString(),
      },
    });
  }, [list?.enrichmentColumns, api, id, showSnackbar, fetch, rowSelectionModel]);
  const stopEnrichments = useCallback(async () => {
    await api.lists.stopAllEnrichments(id!);
    fetch();
  }, [api, fetch, id]);
  const stopImports = useCallback(async () => {
    await api.lists.stopAllImports(id!);
    fetch();
  }, [api, fetch, id]);
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
  const config = getConfig();
  return (
    <LoadingContainer isLoading={!list}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography level="h3">
            {list?.name}
          </Typography>
          {
            list && list?.numBusinessesImporting > 0 &&
            <Chip
              color='primary'
              variant='outlined'
              className={classes.importChip}
            >
              <div className={classes.importChipLabel}>
                Importing ({list.numBusinessesImporting})
                <Tooltip title='Cancel imports'>
                  <IconButton
                    onClick={stopImports}
                    className={classes.chipCancelButton}
                    color='danger'
                    variant='outlined'
                  >
                    <Block className={classes.chipCancelIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            </Chip>
          }
          {
            list && list.businessImportErrors.length > 0 &&
            <Chip
              color='danger'
              variant='outlined'
              className={classes.importChip}
              onClick={() => setShowImportErrorModal(true)}
            >
              <div className={classes.importChipLabel}>
                Import Error ({list.businessImportErrors.length})
              </div>
            </Chip>
          }
          {
            list && list?.numBusinessesEnriching > 0 &&
            <Chip
              color='success'
              variant='outlined'
              className={classes.importChip}
            >
              <div className={classes.importChipLabel}>
                Enriching ({list.numBusinessesEnriching})
                <Tooltip title='Cancel enrichments'>
                  <IconButton
                    className={classes.chipCancelButton}
                    onClick={stopEnrichments}
                    color='danger'
                    variant='outlined'
                  >
                    <Block className={classes.chipCancelIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            </Chip>
          }
          <div className={classes.headerButtons}>
            {
              rowSelectionModel.length > 0 &&
              <>
                <Button
                  color='success'
                  onClick={runEnrichmentsOnSelected}
                  className={classes.headerButton}
                >
                  <PlayArrow />
                  Run ({rowSelectionModel.length})
                </Button>
                <Button
                  color='danger'
                  onClick={removeFromList}
                  className={classes.headerButton}
                >
                  <Delete />
                  Remove ({rowSelectionModel.length})
                </Button>
                <Dropdown>
                  <MenuButton
                    color='primary'
                    variant='solid'
                    size='sm'
                    className={classes.headerButton}
                  >
                    <DriveFileMove />
                    Move ({rowSelectionModel.length})
                  </MenuButton>
                  <Menu placement='bottom-start' className={classes.listsMenu}>
                    {
                      lists
                        ?.filter((l) => l.id !== id)
                        .map((l) => (
                          <MenuItem
                            key={l.id}
                            onClick={() => moveToList(l.id)}
                          >
                            {l.name}
                          </MenuItem>
                        ))
                    }
                    <MenuItem
                      onClick={() => { setShowCreateModal(true); }}
                    >
                      <Add />  Create List
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </>
            }
            <Button
              color='success'
              variant='outlined'
              className={classes.headerButton}
              onClick={() => {
                setEnrichmentModalVal(null);
                logEvent({
                  name: 'enrichment_create_start',
                  type: 'click',
                });
                setShowEnrichmentModal(true);
              }}
            >
              <AutoAwesome />
              Add Enrichment
            </Button>
            {
              list && list.businesses.length > 0 &&
              <Button
                color='warning'
                variant='outlined'
                className={classes.headerButton}
                onClick={() => {
                  setShowSimilarBusinesses(true);
                }}
              >
                <SavedSearch />
                Similar Businesses
              </Button>
            }
            <Button
              color='primary'
              variant='outlined'
              className={classes.headerButton}
              onClick={() => {
                if (subscription?.subscription) {
                  window.location.href = `${config.hosts.api}/lists/${id}/download`;
                } else {
                  setShowSubscriptionModal(true);
                }
              }}
            >
              <CloudDownload />
              Export
            </Button>
            <Dropdown>
              <Tooltip title="More Options">
                <MenuButton color='primary'>
                  <MoreVert />
                </MenuButton>
              </Tooltip>
              <Menu placement='bottom-start'>
                <MenuItem
                  onClick={() => {
                    setShowImportModal(true);
                  }}
                >
                  <UploadFile />
                  Import
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowDuplicateModal(true);
                  }}
                >
                  <ContentCopy />
                  Duplicate
                </MenuItem>
                <MenuItem onClick={() => setShowRenameModal(true)}>
                  <Edit />
                  Rename
                </MenuItem>
                <MenuItem
                  color='danger'
                  onClick={() => {
                    deleteList();
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>
        </div>
        {
          creditsLeft != null && creditsLeft <= 0 &&
          <Card color='danger' variant='soft' className={classes.noMoreCreditsBanner}>
            <Typography level='body-md'>
              <b>
                You have no credits left to run enrichments!
              </b>
            </Typography>
            <Button
              color='success'
              variant='solid'
              onClick={() => {
                navigate('/settings');
              }}
            >
              Get More
            </Button>
          </Card>
        }
        <div className={classes.tableContainer}>
          {
            list?.businesses.length === 0 ?
              <Card className={classes.emptyState} variant='plain'>
                {
                  list?.numBusinessesImporting > 0 ?
                    <>
                      <Typography level='h4'>
                        Your list is being imported
                      </Typography>
                      <Typography level='body-md'>
                        Imported businesses will start showing here in a few seconds
                      </Typography>
                    </> :
                    <>
                      <Typography level='h4'>
                        This list is empty
                      </Typography>
                      <Typography level='body-md'>
                        Add businesses from the Search page, or import them from a CSV file
                      </Typography>
                      <Button
                        onClick={() => setShowImportModal(true)}
                        size='md'
                        className={classes.uploadButton}
                      >
                        <UploadFile />
                        Import
                      </Button>
                    </>
                }
              </Card> :
              <DataGridPro
                slots={{
                  footer: Footer,
                }}
                slotProps={{
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'top-start',
                  },
                  footer: {
                    // @ts-expect-error this is an extra prop
                    setFilterButtonEl,
                  },
                }}
                pagination
                checkboxSelection
                apiRef={gridApiRef}
                disableRowSelectionOnClick
                rowCount={list?.size}
                onColumnResize={saveColumnsState}
                loading={list == null}
                paginationMode='server'
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={setRowSelectionModel}
                columnVisibilityModel={visibilityModel}
                onColumnVisibilityModelChange={(model) => {
                  setVisibilityModel(model);
                  saveColumnsState();
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => {
                  setSortModel(model);
                  saveColumnsState();
                }}
                onColumnOrderChange={saveColumnsState}
                getRowHeight={() => 'auto'}
                experimentalFeatures={{ columnGrouping: true }}
                showCellVerticalBorder
                pageSizeOptions={[pageSize]}
                showColumnVerticalBorder
                classes={{
                  virtualScroller: classes.tableScroller,
                }}
                pinnedColumns={{
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'about'],
                }}
                columnHeaderHeight={32}
                columnGroupingModel={
                  list?.enrichmentColumns
                    .map((col) => {
                      if (col.type === 'CONTACT_INFO') {
                        return {
                          groupId: col.id,
                          children: [{
                            field: `${col.id}-first-name`,
                          }, {
                            field: `${col.id}-last-name`,
                          }, {
                            field: `${col.id}-title`,
                          }, {
                            field: `${col.id}-email`,
                          }, {
                            field: `${col.id}-linkedin`,
                          }],
                          renderHeaderGroup: () => (
                            <EnrichmentHeader
                              col={col}
                              listId={id!}
                              getFirstTenRowIds={getFirstTenRowIds}
                              showEnrichmentModal={() => {
                                setEnrichmentModalVal(col);
                                setShowEnrichmentModal(true);
                              }}
                              resetSelectedRows={() => setRowSelectionModel([])}
                              selectedRows={rowSelectionModel as string[]}
                              columnWidths={columnWidths}
                              refetch={fetch}
                            />
                          ),
                        };
                      }
                      if (col.type === 'PITCHBOOK') {
                        return {
                          groupId: col.id,
                          children: [{
                            field: `${col.id}-url`,
                          }, {
                            field: `${col.id}-employee-count`,
                          }, {
                            field: `${col.id}-financing`,
                          }, {
                            field: `${col.id}-deal`,
                          }, {
                            field: `${col.id}-ownership`,
                          }, {
                            field: `${col.id}-acquirer`,
                          }],
                          renderHeaderGroup: () => (
                            <EnrichmentHeader
                              col={col}
                              listId={id!}
                              getFirstTenRowIds={getFirstTenRowIds}
                              showEnrichmentModal={() => {
                                setEnrichmentModalVal(col);
                                setShowEnrichmentModal(true);
                              }}
                              columnWidths={columnWidths}
                              selectedRows={rowSelectionModel as string[]}
                              resetSelectedRows={() => setRowSelectionModel([])}
                              refetch={fetch}
                            />
                          ),
                        };
                      }
                      if (col.type === 'LINKEDIN') {
                        return {
                          groupId: col.id,
                          children: [{
                            field: `${col.id}-url`,
                          }, {
                            field: `${col.id}-connected-employees`,
                          }, {
                            field: `${col.id}-founded`,
                          }, {
                            field: `${col.id}-followers`,
                          }, {
                            field: `${col.id}-size`,
                          }],
                          renderHeaderGroup: () => (
                            <EnrichmentHeader
                              col={col}
                              listId={id!}
                              getFirstTenRowIds={getFirstTenRowIds}
                              showEnrichmentModal={() => {
                                setEnrichmentModalVal(col);
                                setShowEnrichmentModal(true);
                              }}
                              columnWidths={columnWidths}
                              selectedRows={rowSelectionModel as string[]}
                              resetSelectedRows={() => setRowSelectionModel([])}
                              refetch={fetch}
                            />
                          ),
                        };
                      }
                      return null;
                    })
                    .filter((col) => col != null) as
                  {
                    groupId: string;
                    children: { field: string; }[];
                  }[] ?? []
                }
                columns={columns}
                rows={list?.businesses ?? []}
              />
          }
        </div>
        {
          list &&
          <BusinessDrawer
            initialBusinessId={selectedBusinessId}
            onClose={() => setSelectedBusinessId(null)}
            currentList={{
              id: id!,
              add: async (businessId: string) => {
                await api.lists.addToList(id!, {
                  businessIds: [businessId],
                });
                await fetch();
                showSnackbar({
                  message: 'Added to list!',
                  color: 'success',
                });
              },
            }}
            enrichments={{
              cols: list!.enrichmentColumns!,
              values: list!.businesses.find((b) =>
                b.id === selectedBusinessId)?.enrichmentValues,
            }}
          />
        }
        {
          showEnrichmentModal &&
          <EnrichmentModal
            enrichment={enrichmentModalVal}
            listId={id!}
            onClose={async (modified) => {
              setShowEnrichmentModal(false);
              if (modified) {
                await fetch();
                setTimeout(() => {
                  let focusId;
                  if (modified.type === 'CONTACT_INFO') {
                    focusId = `${modified.id}-linkedin`;
                  } else if (modified.type === 'PITCHBOOK') {
                    focusId = `${modified.id}-ownership`;
                  } else if (modified.type === 'LINKEDIN') {
                    focusId = `${modified.id}-size`;
                  } else {
                    focusId = modified.id;
                  }
                  const colIndex = gridApiRef.current.getColumnIndex(focusId);
                  gridApiRef.current.scrollToIndexes({
                    rowIndex: 0,
                    colIndex,
                  });
                  gridApiRef.current?.setColumnHeaderFocus(focusId);
                }, 100);
              }
            }}
          />
        }
        <RenameListModal
          id={id!}
          currentName={list?.name ?? ''}
          open={showRenameModal}
          onClose={() => {
            setShowRenameModal(false);
            fetch();
          }}
        />
        <CreateListModal
          open={showCreateModal}
          onClose={(newListId?: string) => {
            if (newListId) {
              moveToList(newListId);
              fetchLists();
            }
            setShowCreateModal(false);
          }}
        />
        <DuplicateListModal
          id={id!}
          open={showDuplicateModal}
          onClose={() => {
            setShowDuplicateModal(false);
          }}
        />
        {
          showImportModal &&
          <ImportModal
            id={id!}
            onClose={(imported) => {
              setShowImportModal(false);
              if (imported) {
                fetch();
                showSnackbar({
                  color: 'success',
                  message: 'Started importing!',
                });
              }
            }}
          />
        }
        <SubscriptionModal
          open={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
        />
        <ImportErrorModal
          importErrors={list?.businessImportErrors ?? []}
          open={showImportErrorModal}
          onClose={() => setShowImportErrorModal(false)}
          listId={id!}
          refetch={fetch}
        />
        {
          infoModalData &&
          <EnrichmentScrapeStepsModal
            prompt={infoModalData.prompt}
            result={infoModalData.value}
            explanation={infoModalData.explanation ?? ''}
            steps={infoModalData.steps}
            onClose={() => setInfoModalData(null)}
          />
        }
        {
          showSimilarBusinesses &&
          <SimilarBusinessesModal
            listId={id!}
            onClose={async () => {
              setShowSimilarBusinesses(false);
              await fetch();
            }}
          />
        }
      </div>
    </LoadingContainer >
  );
}
